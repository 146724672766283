import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaSearch, FaBuilding, FaUsers, FaBriefcase, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ITEMS_PER_PAGE = 10;

const LinkedInScraper = () => {
  const [activeTab, setActiveTab] = useState('search');
  const [formData, setFormData] = useState({
    locationId: '103035651',
    maxCompanies: 5
  });
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [people, setPeople] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSearch = useCallback((data) => {
    if (!searchTerm) {
      setFilteredData(data);
      return;
    }

    const searchLower = searchTerm.toLowerCase();
    let filtered;

    switch (activeTab) {
      case 'jobs':
        filtered = data.filter(job => 
          job.title?.toLowerCase().includes(searchLower) ||
          job.company_name?.toLowerCase().includes(searchLower) ||
          job.location?.toLowerCase().includes(searchLower)
        );
        break;
      case 'companies':
        filtered = data.filter(company => 
          company.name?.toLowerCase().includes(searchLower) ||
          company.location?.toLowerCase().includes(searchLower)
        );
        break;
      case 'people':
        filtered = data.filter(person => 
          person.name?.toLowerCase().includes(searchLower) ||
          person.headline?.toLowerCase().includes(searchLower) ||
          person.company_name?.toLowerCase().includes(searchLower)
        );
        break;
      default:
        filtered = data;
    }
    
    setFilteredData(filtered);
  }, [searchTerm, activeTab]);

  const fetchData = async () => {
    try {
      const [jobsRes, companiesRes, peopleRes] = await Promise.all([
        axios.get('/api/linkedin/jobs'),
        axios.get('/api/linkedin/companies'),
        axios.get('/api/linkedin/people')
      ]);
      setJobs(jobsRes.data.jobs);
      setCompanies(companiesRes.data.companies);
      setPeople(peopleRes.data.people);

      // Update filtered data based on active tab
      switch (activeTab) {
        case 'jobs':
          handleSearch(jobsRes.data.jobs);
          break;
        case 'companies':
          handleSearch(companiesRes.data.companies);
          break;
        case 'people':
          handleSearch(peopleRes.data.people);
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when tab changes
    switch (activeTab) {
      case 'jobs':
        handleSearch(jobs);
        break;
      case 'companies':
        handleSearch(companies);
        break;
      case 'people':
        handleSearch(people);
        break;
    }
  }, [activeTab, searchTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/linkedin/scrape', formData);
      await fetchData();
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const renderPagination = () => (
    <div className="flex justify-between items-center mt-4 p-4">
      <button
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className={`flex items-center px-4 py-2 rounded ${
          currentPage === 1 ? 'text-gray-400' : 'text-blue-500 hover:bg-blue-50'
        }`}
      >
        <FaChevronLeft className="mr-2" /> Previous
      </button>
      <span className="text-gray-600">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        className={`flex items-center px-4 py-2 rounded ${
          currentPage === totalPages ? 'text-gray-400' : 'text-blue-500 hover:bg-blue-50'
        }`}
      >
        Next <FaChevronRight className="ml-2" />
      </button>
    </div>
  );

  const renderSearchBar = () => (
    <div className="mb-4">
      <div className="relative">
        <FaSearch className="absolute left-3 top-3 text-gray-400" />
        <input
          type="text"
          placeholder={`Search ${activeTab}...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  const renderPersonCard = (person) => (
    <div key={person._id} className="bg-white rounded-lg shadow-md p-6 mb-4 hover:shadow-lg transition-shadow">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <img
            src={person.profilePicture || "https://via.placeholder.com/100"}
            alt={person.name}
            className="w-16 h-16 rounded-full object-cover"
          />
        </div>
        <div className="flex-grow">
          <h3 className="text-xl font-semibold text-gray-800">{person.name}</h3>
          <p className="text-gray-600">{person.headline}</p>
          <p className="text-sm text-gray-500 mt-1">{person.company_name} • {person.location}</p>
          <div className="mt-3">
            <a
              href={person.profile_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 font-medium"
            >
              View Profile →
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    if (activeTab === 'search') {
      return (
        <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow">
          <div>
            <label className="block text-gray-700 font-medium mb-2">Location ID:</label>
            <input
              type="text"
              name="locationId"
              value={formData.locationId}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              placeholder="103035651 (Berlin)"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Max Companies:</label>
            <input
              type="number"
              name="maxCompanies"
              value={formData.maxCompanies}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              min="1"
              max="20"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors
              ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Scraping...' : 'Start Scraping'}
          </button>
        </form>
      );
    }

    const paginatedData = getPaginatedData();
    
    return (
      <div>
        {renderSearchBar()}
        <div className="space-y-4">
          {paginatedData.map(item => {
            switch (activeTab) {
              case 'jobs':
                return (
                  <div key={item._id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                    <h3 className="text-xl font-semibold text-gray-800">{item.title}</h3>
                    <p className="text-gray-600 mt-2">{item.company_name} • {item.location}</p>
                    <p className="text-sm text-gray-500 mt-1">{item.type} • {item.post_date}</p>
                    <div className="mt-3">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700 font-medium"
                      >
                        View Job →
                      </a>
                    </div>
                  </div>
                );
              case 'companies':
                return (
                  <div key={item._id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                    <div className="flex items-center space-x-4">
                      {item.logo && (
                        <img src={item.logo} alt={item.name} className="w-16 h-16 rounded-full object-cover" />
                      )}
                      <div>
                        <h3 className="text-xl font-semibold text-gray-800">{item.name}</h3>
                        <a
                          href={item.linkedin_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:text-blue-700 font-medium"
                        >
                          View Company →
                        </a>
                      </div>
                    </div>
                  </div>
                );
              case 'people':
                return renderPersonCard(item);
              default:
                return null;
            }
          })}
        </div>
        {renderPagination()}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">LinkedIn Scraper</h1>

      {/* Tabs */}
      <div className="flex mb-6 bg-white rounded-lg shadow-sm">
        <button
          onClick={() => setActiveTab('search')}
          className={`flex items-center px-6 py-3 ${
            activeTab === 'search'
              ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaSearch className="mr-2" /> Search
        </button>
        <button
          onClick={() => setActiveTab('jobs')}
          className={`flex items-center px-6 py-3 ${
            activeTab === 'jobs'
              ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaBriefcase className="mr-2" /> Jobs
        </button>
        <button
          onClick={() => setActiveTab('companies')}
          className={`flex items-center px-6 py-3 ${
            activeTab === 'companies'
              ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaBuilding className="mr-2" /> Companies
        </button>
        <button
          onClick={() => setActiveTab('people')}
          className={`flex items-center px-6 py-3 ${
            activeTab === 'people'
              ? 'text-blue-600 border-b-2 border-blue-600 font-medium'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <FaUsers className="mr-2" /> People
        </button>
      </div>

      {/* Content */}
      {renderContent()}
    </div>
  );
};

export default LinkedInScraper;